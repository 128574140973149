import React from 'react';
import { Helmet } from 'react-helmet';
import './Mission.css';

const Mission = () => {
  return (
    <div className="mission-page">
      <Helmet>
        <title>Mission - The Other Side</title>
        <meta name="description" content="Learn about The Other Side's mission to provide curated, high-quality, and balanced content for informed discussions." />
        <meta name="keywords" content="mission, balanced perspectives, curated content, quality information" />
        <link rel="canonical" href="https://www.theotherside.media/mission" />
      </Helmet>

      {/* Flex container for Mission Statement and Guiding Principles */}
      <div className="mission-flex-container">
        <section className="mission-section mission-statement">
          <h2><b>The Other Side's Mission</b></h2>

          <h3>The Evolution of Media</h3>
          <p>
            The advent of the internet revolutionized the media landscape, transforming it from a highly curated system dominated by a few powerful media companies into an unstructured, open environment where information is more democratized but harder to regulate for quality. 
          </p>
          <p>
            The rise of the internet lowered barriers to entry for content creation, allowing anyone to publish and share information globally. While this has fostered diversity, it has also made enforcing quality control difficult.
          </p>

          {/* Straight from the Horse's Mouth as a subheader */}
          <h3>Straight from the Horse's Mouth</h3>
          <p>
            Media misrepresentations and selective quoting have contributed to the erosion of trust in traditional media. At The Other Side, we aim to provide long-form videos and podcasts where domain experts share contrasting views, allowing listeners to form an unbiased perspective.
          </p>
        </section>

        <section className="mission-section guiding-principles">
          <h3><b>Our Guiding Principles</b></h3>
          <div className="principle">
            <h4>Embrace the Grey Area</h4>
            <p>
              People tend to see topics in black-and-white terms, but most issues exist in a "grey" area. Our mission is to encourage nuanced thinking and embrace the complexities of different perspectives.
            </p>
          </div>
          <div className="principle">
            <h4>Distinguish Between Facts and Opinions</h4>
            <p>
              We clearly separate facts from opinions, supplementing informed opinions with factual Q&A to help our audience make informed decisions.
            </p>
          </div>
          <div className="principle">
            <h4>Be Curators, Not Platforms</h4>
            <p>
              We focus on quality over quantity, actively seeking expert content to help users navigate the chaos of information online.
            </p>
          </div>
          <div className="principle">
            <h4>Resist Content Moderation</h4>
            <p>
              We are not here to moderate public discourse or enforce moral values. Our platform is a library of opinions, free from unnecessary content moderation.
            </p>
          </div>
          <div className="principle">
            <h4>Infinite Optimism</h4>
            <p>
              We strive to balance the media's focus on negativity by highlighting the tremendous progress humanity has made on many issues, fostering a more balanced worldview.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mission;
